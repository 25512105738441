<template>
  <div>
    <CContainer>
      <CRow>
        <CCol md="12">
          <CCardGroup>
            <CCard>
              <CCardHeader>
                <h3 v-if="$route.params.id">Редактирование FAQ</h3>
                <h3 v-else>Создание FAQ</h3>
              </CCardHeader>
              <CCardBody>
                <CForm @submit.prevent="requestFaqUpdate">
                  <CInput
                    v-model="faqData.name"
                    label="Название FAQ"
                    placeholder=""
                    @input="requestFaqUpdate"
                  >
                  </CInput>
                  <div v-if="faq">
                    <CRow>
                      <CCol>
                        <h3>Разделы</h3>
                      </CCol>
                    </CRow>
                    <CRow v-if="sections.length">
                      <CCol>
                        <div v-for="(section, index) in sections" :key="index">
                          <CRow>
                            <CCol md="8">
                              <CTextarea
                                rows="4"
                                v-model="sections[index].text"
                                :label="`Заголовк раздела ${index + 1}`"
                                placeholder=""
                                @input="requestSectionUpdate(index)"
                              >
                              </CTextarea>
                            </CCol>
                            <CCol>
                              <CButton
                                style="margin-top: 28px"
                                color="danger"
                                type="button"
                                @click="deleteSection(index)"
                              >
                                <span>Удалить</span>
                              </CButton>
                            </CCol>
                          </CRow>
                          <CRow style="margin-bottom: 60px">
                            <CCol md="1"> </CCol>
                            <CCol md="11">
                              <CRow>
                                <CCol>
                                  <h5>Вопросы</h5>
                                </CCol>
                              </CRow>
                              <CRow v-if="questions[section.id]">
                                <CCol>
                                  <div
                                    v-for="(question, index) in questions[
                                      section.id
                                    ]"
                                    :key="index"
                                  >
                                    <CRow>
                                      <CCol md="8">
                                        <CTextarea
                                          rows="4"
                                          v-model="
                                            question.question_text
                                          "
                                          :label="`Текст вопроса ${index + 1}`"
                                          placeholder=""
                                          @input="requestQuestionUpdate(section.id, index)"
                                        >
                                        </CTextarea>
                                        <CTextarea
                                          rows="4"
                                          v-model="question.answer_text"
                                          :label="`Текст ответа ${index + 1}`"
                                          placeholder=""
                                          @input="requestQuestionUpdate(section.id, index)"
                                        >
                                        </CTextarea>
                                      </CCol>
                                      <CCol>
                                        <CButton
                                          style="margin-top: 28px"
                                          color="danger"
                                          type="button"
                                          @click="deleteQuestion(section.id, index)"
                                        >
                                          <span>Удалить</span>
                                        </CButton>
                                      </CCol>
                                    </CRow>
                                  </div>
                                </CCol>
                              </CRow>
                              <div v-if="newQuestionsData[section.id]">
                                <CRow>
                                  <CCol>
                                    <h5>Добавить вопрос</h5>
                                  </CCol>
                                </CRow>
                                <CRow>
                                  <CCol md="5">
                                    <CTextarea
                                      rows="4"
                                      v-model="newQuestionsData[section.id].question_text"
                                      :label="`Текст вопроса ${index + 1}`"
                                      placeholder=""
                                    >
                                    </CTextarea>
                                    <CTextarea
                                      rows="4"
                                      v-model="newQuestionsData[section.id].answer_text"
                                      :label="`Текст ответа ${index + 1}`"
                                      placeholder=""
                                    >
                                    </CTextarea>
                                  </CCol>

                                  <CCol>
                                    <CButton
                                      style="margin-top: 28px"
                                      color="success"
                                      type="button"
                                      @click="addQuestion(section.id)"
                                      :disabled="!newQuestionValid(section.id)"
                                    >
                                      <span>Добавить</span>
                                    </CButton>
                                  </CCol>
                                </CRow>
                              </div>
                            </CCol>
                          </CRow>
                          <br />
                          <br />
                          <br />
                        </div>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol>
                        <h3>Добавить раздел</h3>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="8">
                        <CTextarea
                          rows="2"
                          v-model="sectionData.text"
                          label="Название раздела"
                          placeholder=""
                        >
                        </CTextarea>
                      </CCol>
                      <CCol>
                        <CButton
                          style="margin-top: 28px"
                          color="success"
                          type="button"
                          @click="addSection"
                          :disabled="!newSectionValid()"
                        >
                          <span>Добавить</span>
                        </CButton>
                      </CCol>
                    </CRow>
                  </div>
                  <CRow>
                    <CCol>
                      <CButton color="success" type="submit" disabled>
                        <span v-if="$route.params.id">Сохранить</span>
                        <span v-else>Создать</span>
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { debounce } from "lodash";
import { router } from "../router";
import { makeCoreUICSelectList } from "../helpers";
export default {
  name: "FaqForm",
  data() {
    return {
      faq: null,
      sections: [],
      questions: {
        count: -1,
      },
      newQuestionsData: {},
      faqData: {
        id: null,
        name: "",
      },
      sectionData: {
        text: "",
      },
      questionData: {
        question_text: "",
        answer_text: "",
      },
      router: router,
    };
  },

  created() {
    this.storeSubscription = this.$store.subscribe((mutation) => {
      if (mutation.type === "faqs/getByIdSuccess") {
        this.faq = mutation.payload;
        this.faqData.id = this.faq.id;
        this.faqData.name = this.faq.name;
        this.sectionData.faqId = this.faq.id;
      } else if (mutation.type === "faqs/createSuccess") {
        this.faq = mutation.payload;
        this.faqData.id = this.faq.id;
        this.faqData.name = this.faq.name;
        this.sectionData.faqId = this.faq.id;
        router.push({
          name: "faq-edit",
          params: { id: this.faq.id },
        });
      } else if (mutation.type === "faqSections/createSuccess") {
        this["faqSections/getByFaqId"](this.faq.id);
        this.sections[mutation.payload.id] = {};
      } else if (mutation.type === "faqQuestions/createSuccess") {
        this["faqQuestions/getByFaqSectionId"](mutation.payload.faqSectionId);
      } else if (mutation.type === "faqSections/getByFaqIdSuccess") {
        this.sections = mutation.payload.sections;
        for (const section of this.sections) {
          this.newQuestionsData = {
            ...this.newQuestionsData,
            [section.id]: {
              faqSectionId: section.id,
              question_text: "",
              answer_text: ""
            },
          };
          this["faqQuestions/getByFaqSectionId"](section.id);
        }
      } else if (mutation.type === "faqQuestions/getByFaqSectionIdSuccess") {
        this.questions = {
          ...this.questions,
          [mutation.payload.faqSectionId]: mutation.payload.questions,
        };
      }
    });
    if (this.$route.params.id) {
      this["faqs/getById"](this.$route.params.id);
      this["faqSections/getByFaqId"](this.$route.params.id);
    }
  },
  beforeDestroy() {
    if (this.storeSubscription) {
      this.storeSubscription();
    }
  },
  methods: {
    ...mapActions([
      "faqs/create",
      "faqs/update",
      "faqs/getById",
      "faqSections/getByFaqId",
      "faqSections/create",
      "faqSections/update",
      "faqSections/delete",
      "faqQuestions/getByFaqSectionId",
      "faqQuestions/create",
      "faqQuestions/update",
      "faqQuestions/delete",
    ]),
    newQuestionValid(sectionId) {
      if (this.newQuestionsData[sectionId]) {
        return (
          this.newQuestionsData[sectionId].question_text.trim() !== "" &&
          this.newQuestionsData[sectionId].answer_text.trim() !== ""
        );
      }
    },
    newSectionValid() {
      return (
        this.sectionData.text.trim() != ""
      );
    },
    faqNameValid() {
      return this.faqData.name.trim() != "";
    },
    formValid() {
      return this.faqNameValid();
    },
    addSection() {
      this["faqSections/create"](this.sectionData);
      this.sectionData.text = "";
    },
    deleteSection(index) {
      this["faqSections/delete"]({
        faqId: this.faq.id,
        faqSectionId: this.sections[index].id,
      });
      this.sections.splice(index, 1);
    },
    addQuestion(sectionId) {
      this["faqQuestions/create"](this.newQuestionsData[sectionId]);
      this.newQuestionsData[sectionId].question_text = "";
      this.newQuestionsData[sectionId].answer_text = "";
    },
    deleteQuestion(sectionId, index) {
      this["faqQuestions/delete"]({
        faqSectionId: sectionId,
        faqQuestionId: this.questions[sectionId][index].id,
      });
      this.questions[sectionId].splice(index, 1);
    },

    requestFaqUpdate: debounce(function () {
      this.updateFaq();
    }, 500),

    updateFaq() {
      if (this.faqData.id) {
        this["faqs/update"]({
          id: this.faq.id,
          name: this.faqData.name,
        });
      } else {
        this["faqs/create"]({
          name: this.faqData.name,
        });
      }
    },

    requestSectionUpdate: debounce(function (index) {
      this.updateSection(index);
    }, 500),

    updateSection(index) {
      this["faqSections/update"]({
        faqId: this.faq.id,
        id: this.sections[index].id,
        text: this.sections[index].text,
      });
    },

    requestQuestionUpdate: debounce(function (faqSectionId, index) {
      this.updateQuestion(faqSectionId, index);
    }, 500),

    updateQuestion(faqSectionId, index) {
      this["faqQuestions/update"]({
        faqSectionId: faqSectionId,
        id: this.questions[faqSectionId][index].id,
        question_text: this.questions[faqSectionId][index].question_text,
        answer_text: this.questions[faqSectionId][index].answer_text,
      });
    },
  },
};
</script>
